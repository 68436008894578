import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

export const getDay = (date) => {
    return dayjs(date).format("YYYY-MM-DD");
};

export const getDateName = (date) => {
    return dayjs(date).format("dddd, MMMM D, YYYY");
};

export const getDayName = (date) => {
    return dayjs(date).format("ddd");
};

export const getDateNameSlash = (date) => {
    return dayjs(date).format("DD/MM/YYYY");
};
export const getDateNameSlashShortYear = (date) => {
    return dayjs(date).format("DD/MM/YY");
};

export const getDateNameLao = (date) => {
    const [day, _month, year] = dayjs(date).format("DD MM YYYY").split(" ");
    let month = "";
    switch (_month) {
        case "01":
            month = "ມັງກອນ";
            break;
        case "02":
            month = "ກຸມພາ";
            break;
        case "03":
            month = "ມີນາ";
            break;
        case "04":
            month = "ເມສາ";
            break;
        case "05":
            month = "ພຶດສະພາ";
            break;
        case "06":
            month = "ມິຖຸນາ";
            break;
        case "07":
            month = "ກໍລະກົດ";
            break;
        case "08":
            month = "ສິງຫາ";
            break;
        case "09":
            month = "ກັນຍາ";
            break;
        case "10":
            month = "ຕຸລາ";
            break;
        case "11":
            month = "ພະຈິກ";
            break;
        case "12":
            month = "ທັນວາ";
            break;
    }
    return `${day} ${month} ${year}`;
};

export const getResultTime = (date) => {
    return dayjs(date).tz("Asia/Bangkok").format("HH:mm");
};

export const getResultTimeCal = (date, mode, hours) => {
    if (mode == "add") {
        return dayjs(date).tz("Asia/Bangkok").add(hours, "hour").format("HH:mm");
    } else {
        return dayjs(date).tz("Asia/Bangkok").subtract(hours, "hour").format("HH:mm");
    }
};

export const getResultTime2 = (date) => {
    const time = date?.split("T")[1];
    console.log({ time, date: dayjs(date).tz("Asia/Bangkok").format("HH:mm") });
    return dayjs(date).tz("Asia/Bangkok").format("HH:mm");
};

export const getDateSlashToDateDefault = (_date) => {
    const date = _date.split("/");
    return date[2] + "-" + date[1] + "-" + date[0];
};

export const getResultTimeFull = (date) => {
    return dayjs(date).tz("Asia/Bangkok").format("HH:mm:ss");
};
