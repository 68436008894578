import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Grid, Image, Modal } from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Container from "../../components/Container";
import { DOW_JONES_TV_COPY_ID } from "../../constants/Configs";
import { useGetHistoryRewardLottoMutation, useGetRewardLottoMutation } from "../../servies/RewardService";
import { getDateName, getDateSlashToDateDefault, getResultTimeFull } from "../../utils/ConvertDate";
import { generateData } from "../../utils/GenerateNumber";

const color = "#032a67";
const { useBreakpoint } = Grid;

export default function DowJonesTvOldPage() {
    const screens = useBreakpoint();

    // const [date, setDate] = useState(new Date());
    // const [result, setResult] = useState({
    //     firstPrize: "96008",
    //     countDown: "04:45:00",
    //     historyData: [
    //         {
    //             lotto_date: "2024-07-12",
    //             results: {
    //                 digit5: "53710",
    //             },
    //         },
    //         {
    //             lotto_date: "2024-07-11",
    //             results: {
    //                 digit5: "37624",
    //             },
    //         },
    //         {
    //             lotto_date: "2024-07-10",
    //             results: {
    //                 digit5: "16479",
    //             },
    //         },
    //         {
    //             lotto_date: "2024-07-09",
    //             results: {
    //                 digit5: "81058",
    //             },
    //         },
    //         {
    //             lotto_date: "2024-07-08",
    //             results: {
    //                 digit5: "15476",
    //             },
    //         },
    //         {
    //             lotto_date: "2024-07-07",
    //             results: {
    //                 digit5: "83945",
    //             },
    //         },
    //         {
    //             lotto_date: "2024-07-06",
    //             results: {
    //                 digit5: "12495",
    //             },
    //         },
    //         {
    //             lotto_date: "2024-07-05",
    //             results: {
    //                 digit5: "47024",
    //             },
    //         },
    //         {
    //             lotto_date: "2024-07-04",
    //             results: {
    //                 digit5: "53271",
    //             },
    //         },
    //         {
    //             lotto_date: "2024-07-03",
    //             results: {
    //                 digit5: "34566",
    //             },
    //         },
    //         {
    //             lotto_date: "2024-07-02",
    //             results: {
    //                 digit5: "18293",
    //             },
    //         },
    //         {
    //             lotto_date: "2024-07-01",
    //             results: {
    //                 digit5: "75121",
    //             },
    //         },
    //     ],
    // });

    const [data, setData] = useState({});
    const [statusReward, setStatusReward] = useState("PREPARE");
    const [rewardDetail, setRewardDetail] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const [getReward] = useGetRewardLottoMutation();
    const [getHistoryReward] = useGetHistoryRewardLottoMutation();

    const [reloadTime, setReloadTime] = useState("");
    const [currentTime, setCurrentTime] = useState("");
    const [time, setTime] = useState(0);
    const convertTime = (date) => {
        return dayjs(date).tz("Asia/Bangkok").format("HH:mm:ss");
    };

    const interval1Ref = useRef(null);
    const interval2Ref = useRef(null);

    useEffect(() => {
        if (statusReward === "LOADING") {
            interval1Ref.current = setInterval(() => {
                setNumber(
                    `${Math.floor(Math.random() * 9 + 1)}${Math.floor(Math.random() * 9 + 1)}${Math.floor(Math.random() * 9 + 1)}${Math.floor(Math.random() * 9 + 1)}${Math.floor(Math.random() * 9 + 1)}`
                );
            }, 100);
        }

        if (statusReward !== "ANNOUNCED") {
            interval2Ref.current = setInterval(() => {
                if (currentTime) {
                    const now = convertTime(dayjs(currentTime).tz("Asia/Bangkok").add(time, "s").toISOString());
                    const reload_time = convertTime(reloadTime);
                    setTime((prev) => prev + 10);
                    if (statusReward == "ANNOUNCED") {
                        clearInterval(interval2Ref.current);
                    }
                    if (reload_time < now && statusReward !== "ANNOUNCED") {
                        fetchReward();
                    }
                }
            }, 10000);
        }
        return () => {
            if (interval1Ref.current) clearInterval(interval1Ref.current);
            if (interval2Ref.current) clearInterval(interval2Ref.current);
        };
    }, [currentTime, reloadTime, statusReward, time]);

    const fetchReward = useCallback(async () => {
        try {
            const result = await getReward({ huayId: DOW_JONES_TV_COPY_ID }).unwrap();
            const result_history = await getHistoryReward({ huayId: DOW_JONES_TV_COPY_ID }).unwrap();

            setReloadTime(result?.data?.reloadTime);
            setCurrentTime(result?.data?.serverTime);

            setStatusReward(result?.data?.viewStatus);
            setRewardDetail(result?.data);

            if (result?.status !== "0000") {
                clearInterval(interval1Ref.current);
                clearInterval(interval2Ref.current);
                setNumber("-----");
            }
            if (result?.data?.viewStatus === "ANNOUNCED") {
                const _data = generateData(result?.data?.result?.primaryNumber);
                setData(_data);
            }

            const _result_history =
                result_history?.data?.map((item) => {
                    return {
                        date: item?.roundName,
                        result: item?.result?.primaryNumber,
                    };
                }) || [];
            setHistoryData([..._result_history]);
        } catch (error) {
            Modal.error({
                title: "An error occurred. !",
                content: "Please try again.",
                okText: "Close",
                okType: "danger",
            });
        } finally {
            setLoading(false);
            setLoadingHistory(false);
        }
    }, [getReward, getHistoryReward]);

    useEffect(() => {
        document.title = "DOW JONES TV";
        fetchReward();
    }, [fetchReward]);

    const [number, setNumber] = useState("00000");

    const RenderResult = ({ number }) => {
        return (
            <Flex
                align="center"
                justify="center"
                className="text-[84px]  text-black font-black !w-[60px]"
                style={{
                    lineHeight: 1.2,
                }}
            >
                {number}
            </Flex>
        );
    };

    const RenderResultHistory = ({ date, result }) => {
        return (
            <Flex
                className="w-full bg-white p-1 rounded-lg border-solid border-[#000] border-[1px] hover:bg-[#eee] hover:border-[#00a14b]"
                justify="space-between"
                vertical={!screens.lg}
            >
                <div className=" text-[1.2rem] text-[#333] font-medium">{getDateName(getDateSlashToDateDefault(date))}</div>
                <Flex gap={5} justify={!screens.lg ? "center" : "space-between"}>
                    {result?.split("")?.map((item) => (
                        <Flex
                            justify="center"
                            align="center"
                            className="border-solid border-[#c2c2c2] border-[1px] bg-[#eee] w-[35px] h-[35px] rounded-full"
                        >
                            <div className="text-[#000] text-[28px] font-medium">{item}</div>
                        </Flex>
                    ))}
                </Flex>
            </Flex>
        );
    };

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={require("../../assets/images/tvdowjones/tvdowjones-image-logo.png")} />
            </Helmet>
            {(loading || loadingHistory) && (
                <div className="fixed top-0 left-0 inset-0  flex  justify-center items-center backdrop-opacity-10 backdrop-invert bg-black/50 z-50 ">
                    <LoadingOutlined
                        style={{
                            color: "#fff",
                            fontSize: 40,
                        }}
                        spin
                    />

                    <div className="pl-4 text-sky-50">Loading ...</div>
                </div>
            )}
            <div style={{ height: "100vh", width: "100vw", position: "fixed", zIndex: -2, background: "#a8b1ba" }} />

            <Container className={"relative items-center flex flex-col text-white"}>
                <Flex
                    style={{
                        backgroundColor: "#fff",
                        backgroundImage: `url(${require("../../assets/images/tvdowjones/tvdowjones-warpper.png")})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}
                    className="w-full !max-w-[950px] min-h-[1200px]"
                    vertical
                >
                    {/* header logo */}
                    <Flex align="center" vertical>
                        <Image
                            src={require("../../assets/images/tvdowjones/tvdowjones-header.png")}
                            rootClassName="max-w-[950px] border-solid border-[#007f36] border-b-[8px]"
                            preview={false}
                        />
                        <div className="mt-[5px]">
                            <div className="text-black text-[1.5rem] sm:text-[2rem] font-bold text-center">
                                Dow Jones TV Winning Number
                            </div>
                            <div className="text-[#ba000e] text-[1rem] sm:text-[1.2rem] font-bold text-center">
                                {statusReward === "PREPARE"
                                    ? historyData[0]?.date
                                        ? getDateName(getDateSlashToDateDefault(historyData[0]?.date))
                                        : "-,-,-"
                                    : rewardDetail?.roundName
                                      ? getDateName(getDateSlashToDateDefault(rewardDetail?.roundName))
                                      : "-,-,-"}
                            </div>
                            <div className="text-[#000]  text-[1rem] sm:text-[1.2rem] font-bold text-center">
                                {rewardDetail?.announceAt ? getResultTimeFull(rewardDetail?.announceAt) : "--:--"}
                            </div>
                            <Flex justify="center" align="center">
                                <Flex
                                    justify="center"
                                    align="center"
                                    gap={5}
                                    className="border-solid border-[3px] border-[#ba000e] rounded-lg px-1 bg-[#eee] mt-4"
                                >
                                    {statusReward === "PREPARE"
                                        ? historyData[0]?.result?.split("")?.map((item) => <RenderResult number={item} />)
                                        : statusReward === "ANNOUNCED"
                                          ? rewardDetail?.result?.primaryNumber
                                                ?.split("")
                                                ?.map((item) => <RenderResult number={item} />)
                                          : number?.split("")?.map((item) => <RenderResult number={item} />)}
                                </Flex>
                            </Flex>
                        </div>
                        <div className="text-center mt-12 mb-4 w-full">
                            <Image
                                src={require("../../assets/images/tvdowjones/tvdowjones-1.png")}
                                preview={false}
                                className="!max-w-[760px]"
                            />
                        </div>

                        <div className="max-w-[760px] w-[80%]">
                            <Flex className="bg-[#a90b03] p-1 rounded-[5px]" align="center">
                                <Flex className="p-1 bg-white rounded-full" justify="center" align="center">
                                    <Image
                                        src={require("../../assets/images/tvdowjones/result-icon.png")}
                                        preview={false}
                                        rootClassName="!w-[28px] !h-[28px]"
                                    />
                                </Flex>
                                <div className="font-bold text-[1.2rem] ml-1">Past Dow Jones TV Results</div>
                            </Flex>
                            <Flex vertical gap={5} className="my-[10px]">
                                {historyData?.slice(statusReward === "PREPARE" ? 1 : 0, historyData.length)?.map((item) => (
                                    <RenderResultHistory {...item} />
                                ))}
                            </Flex>

                            <Flex
                                justify="space-between"
                                gap={10}
                                className="my-3 max-w-[760px] w-full"
                                vertical={!screens.sm}
                                align={screens.sm ? "start" : "center"}
                            >
                                <Flex className="border-solid border-[2px] border-[#eee] rounded-xl h-full overflow-hidden md:w-[33.33%] w-full">
                                    <Image
                                        src={require("../../assets/images/tvdowjones/tvdowjones-2.png")}
                                        preview={false}
                                        rootClassName="w-full"
                                    />
                                </Flex>
                                <Flex className="border-solid border-[2px] border-[#eee] rounded-xl h-full overflow-hidden md:w-[33.33%] w-full">
                                    <Image
                                        src={require("../../assets/images/tvdowjones/tvdowjones-3.png")}
                                        preview={false}
                                        rootClassName="w-full"
                                    />
                                </Flex>
                                <Flex className="border-solid border-[2px] border-[#eee] rounded-xl h-full overflow-hidden md:w-[33.33%] w-full">
                                    <Image
                                        src={require("../../assets/images/tvdowjones/tvdowjones-4.png")}
                                        preview={false}
                                        rootClassName="w-full"
                                    />
                                </Flex>
                            </Flex>
                        </div>
                        <div className="w-full text-center bg-[#007f36] py-[15px] text-[1rem] font-bold">
                            Copyright ©2024 - www.tvdowjones.com
                        </div>
                    </Flex>
                </Flex>
            </Container>
        </div>
    );
}
